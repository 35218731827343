import isString from 'lodash/isString';
import get from 'lodash/get';
import Plyr from 'plyr';
import dom from '../../../wrapper/DomWrapper';
import { VIDEO_PROVIDER_NAMES } from '../../../../constants';

const PREVENT_BLACK_LINES_RESERVE = 0.1;
const YOUTUBE_BRANDING_RESERVE = 100;
const youtubeRatio = +(16 / 9).toFixed(2);

class ProviderWrapper {
  constructor(selector, settings) {
    this.video = selector;
    this.dataParams = settings;
  }

  renderVideoOnPage = () => {
    const { hash, isBackground } = this.dataParams;
    const elVideoContainer = dom.getElement(`#v-${hash}`, this.video);
    const elVideo = dom.createElement('div');
    const elStyle = dom.createElement('style');

    dom.addHtml(elStyle, `
      .plyr--video {
          background: transparent!important;
          overflow: unset;
        }
      .plyr__video-wrapper{
        background: transparent!important;
        padding-bottom: 56.25%!important;
        ${isBackground ? 'overflow: unset' : ''}
      }
      .plyr__video-embed__container{
        transform: translateY(${isBackground ? '0' : '-38.28125%'})!important;
        position: unset!important;
        padding-bottom:0!important; 
      }
    `);

    dom.document.head.appendChild(elStyle);

    dom.addHtml(elVideo, `
      <div id="player">
        <video id="video-${hash}" ></video>
      </div>
    `);

    elVideoContainer.appendChild(elVideo);
  };

  addPreviewImage = (player) => {
    const { hash } = this.dataParams;
    const previewContainerId = `#preview-container-${hash}`;
    this.elPreview = dom.getElement(previewContainerId, this.video);

    if (!this.elPreview) return;

    dom.on(this.elPreview, 'click', () => {
      player.play();
    });
  };

  connectVideoDefault = () => {
    const {
      hash, isBackground, autoplay,
    } = this.dataParams;
    const playerId = `#video-${hash}`;

    this.player = new Plyr(playerId, {
      controls: null,
      loadSprite: false,
      muted: isBackground ? true : !!autoplay,
      autoplay: isBackground ? 1 : autoplay,
      loop: {
        active: true,
      },
    });

    this.player.on('ready', this.onPlayerReady);
    this.player.on('playing', this.onPlayerPlaying);
    this.player.on('ended', this.onPlayerEnded);

    return this.player;
  };

  // eslint-disable-next-line class-methods-use-this
  getVideoSize(provider, video) {
    const { width, height } = video;

    switch (provider) {
      case VIDEO_PROVIDER_NAMES.YOUTUBE:
        return {
          width,
          height,
        };
      case VIDEO_PROVIDER_NAMES.VIMEO:
        return {
          height: 240,
          width: 426,
        };
      case VIDEO_PROVIDER_NAMES.DAILYMOTION:
        return {
          height: 270,
          width: 480,
        };
      default:
        return {
          width,
          height,
        };
    }
  }

  getLoopTimeoutTime = () => {
    if (!this.player) return 0;

    return Math.round((this.player.duration - this.player.currentTime) * 1000) - 1500;
  }

  getTimeCode = (startTime) => {
    const isStartTimeInSeconds = !isString(startTime);

    if (isStartTimeInSeconds) return Number(startTime) || 0;

    const re = /(\d{1,2}h)?(\d{1,2}m)?(\d{1,2}s)?/;
    const params = re.exec(startTime);
    const hours = parseInt(params[1], 10) || 0;
    const minutes = parseInt(params[2], 10) || 0;
    const seconds = parseInt(params[3], 10) || 0;

    return hours * 3600 + minutes * 60 + seconds || 0;
  }

  onPlayerReady = () => {
    this.resizeVideo();
  }

  onPlayerEnded = () => {
    clearTimeout(this.loopTimeoutId);
  }

  onPlayerPlaying = () => {
    const isBackground = get(this, ['dataParams', 'isBackground'], false);
    const videoParams = get(this, ['dataParams', 'videoParams'], {});

    this.hidePreview();

    if (!isBackground || !this.player) return;

    const playlistId = get(videoParams, ['list'], null);
    const timeout = this.getLoopTimeoutTime();

    const cb = playlistId
      ? this.playNextVideoInPlaylist
      : this.playSingleVideo;

    this.loopTimeoutId = setTimeout(cb, timeout);
  }

  playNextVideoInPlaylist = () => {
    if (!this.player) return;

    this.player.nextVideo();
  }

  playSingleVideo = () => {
    const startTime = get(this, ['dataParams', 'videoParams', 't'], null);
    this.player.currentTime = this.getTimeCode(startTime);
  }

  hidePreview = () => {
    if (this.elPreview) dom.hide(this.elPreview);
  }

  resizeVideo() {
    const { provider, isSliderBg } = this.dataParams;

    const section = this.video;
    const sectionSize = section.getBoundingClientRect();
    const elVideo = dom.getElement('iframe', this.video);
    const sizeMultiplier = isSliderBg && provider === VIDEO_PROVIDER_NAMES.YOUTUBE
      ? 1 + PREVENT_BLACK_LINES_RESERVE : 1;
    const videoSize = this.getVideoSize(provider, elVideo);

    const { height: sectionHeight, width: sectionWidth } = sectionSize;
    const { width: videoWidth } = videoSize;
    let { height: videoHeight } = videoSize;
    const videoRatio = +(videoWidth / videoHeight).toFixed(2);

    if (provider === VIDEO_PROVIDER_NAMES.YOUTUBE
        && videoRatio < youtubeRatio - 0.03
        && isSliderBg) {
      videoHeight -= YOUTUBE_BRANDING_RESERVE * 2;
    }

    let newVideoHeight = 0;
    let newVideoWidth = 0;

    const widthRatio = sectionWidth / videoWidth;
    const heightRatio = sectionHeight / videoHeight;

    // if height gap is higher than width gap
    if (heightRatio > widthRatio) {
      // video height times 1.1
      newVideoHeight = Math.round(sectionHeight * sizeMultiplier);
      // value of video width adjusted based on ratio between section and video height times 1.1
      newVideoWidth = Math.round(heightRatio * videoWidth * sizeMultiplier);
    } else {
      // video width times 1.1
      newVideoWidth = Math.round(sectionWidth * sizeMultiplier);
      // value of video height adjusted based on ratio between section and video width times 1.1
      newVideoHeight = Math.round(widthRatio * videoHeight * sizeMultiplier);
    }

    if (provider === VIDEO_PROVIDER_NAMES.YOUTUBE && isSliderBg) {
      newVideoHeight += YOUTUBE_BRANDING_RESERVE * 2;
    }

    const videoOffsetTop = (newVideoHeight - sectionHeight) / 2;
    const videoOffsetLeft = (newVideoWidth - sectionWidth) / 2;

    dom.updateStyle(elVideo, {
      height: `${newVideoHeight}px`,
      width: `${newVideoWidth}px`,
      top: `-${videoOffsetTop}px`,
      left: `-${videoOffsetLeft}px`,
    });
  }
}

export default ProviderWrapper;
